import React, { useState, useRef, useEffect } from 'react';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { useAuth, db } from "../../../base";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import planLimits from "../../../plans/plansLimits";
import './FacadeDesigner.css';
import { colorList } from '../../colors/colorHelper'; // Import the helper file
import { getColorByName, normalizeToCamelCase } from '../../colors/colorUtils';


const FacadeDesigner = ({ projectChangeKey, projects, projectId, applyColorsToCanvasItems, newProjectImage, detectAndApplyColors, setIsDetectionTriggered, isDetectionTriggered, resetNewProjectImage, userRole, isDesignerModalOpen, setDesignerModalOpen, handleAnalyticsEvent }) => {
  const { t, i18n } = useTranslation(["translation"]);
  const currentUser = useAuth();
  const textAreaRef = useRef(null);
  const textareaContainerRef = useRef(null); // Add a ref for the textarea container
  const responseContainerRef = useRef(null);
  const modalBodyRef = useRef(null);

  const [userInput, setUserInput] = useState('');
  const [assistantResponse, setAssistantResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [defaultQuestion, setDefaultQuestion] = useState('');
  const [isFirstInteraction, setIsFirstInteraction] = useState(true);
  const [threadId, setThreadId] = useState(null);
  const [runId, setRunId] = useState(null);
  const [imageFileId, setImageFileId] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [colorButtons, setColorButtons] = useState([]);
  const [proposedColors, setProposedColors] = useState({});
  const [conversationHistory, setConversationHistory] = useState([]); // Add this line
  const proposedColorsRef = useRef(proposedColors);
  const [isProjectSwitching, setIsProjectSwitching] = useState(false); // Track project switching state
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Default to English

    // Initialize language on component mount
    useEffect(() => {
      const language = initializeLanguage();
      setCurrentLanguage(language);
    }, []);
  
  useEffect(() => {
    proposedColorsRef.current = proposedColors;
  }, [proposedColors]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   if (textareaContainerRef.current) {
  //     // Scroll the textarea into view after the assistant response is updated
  //     textareaContainerRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [assistantResponse]); // Update positioning after each assistant response

  useEffect(() => {
    // Scroll to textareaContainerRef when assistant response is updated or loading finishes
    if (textareaContainerRef.current && !isLoading) {
      textareaContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [assistantResponse, isLoading]);
  

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        if (currentUser.email.includes('guest')) {
          // Retrieve interaction counts from local storage for guest users
          const weeklyInteractionCount = parseInt(localStorage.getItem('guest_weeklyInteractionCount')) || 0;
          const assistantInteractionCount = parseInt(localStorage.getItem('guest_assistantInteractionCount')) || 0;
  
          // Set user data for guest, including setting the planType as 'Guest'
          setUserData({
            weeklyInteractionCount,
            assistantInteractionCount,
            planType: 'Guest',  // Set planType to 'Guest' for guest users
          });
        } else {
          // Retrieve data from Firestore for regular users
          const userRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setUserData(userDoc.data());
          }
        }
      }
    };
  
    fetchUserData();
  }, [currentUser]);
  
  

  useEffect(() => {
    const hints = t('hints', { returnObjects: true });
    if (hints && hints.length > 0) {
      setDefaultQuestion(hints[0]);
    }
  }, [t]);

  useEffect(() => {
    if (projectChangeKey) {
      // console.log("Project key changed:", projectChangeKey);
      setIsProjectSwitching(true); // Set switching state

      // Find the project and prepare for switch
      const project = projects.find(p => p.id === projectId);
      if (project && project.projectJson) {
        const projectData = JSON.parse(project.projectJson);
        if (projectData && projectData.backgroundImage && projectData.backgroundImage.src) {
          // Proceed to handle the project switch
          handleProjectSwitch(projectData.backgroundImage.src);
          // console.log("switch has been triggered in useeffect")
        }
      }
    }
  }, [projectChangeKey, projects, projectId]);
  

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
    }
  }, [assistantResponse]);

  

  useEffect(() => {
    // Existing logic for processing assistantResponse
  
  }, [assistantResponse, t, i18n.language]);

  useEffect(() => {
    // console.log("Current Run ID in State:", runId);
  }, [runId]);

  const initializeLanguage = () => {
    // Use i18next stored language if available, fallback to browser language
    let language = localStorage.getItem("i18nextLng") || 
                   (navigator.language.startsWith("fr") ? "fr" : "en");
  
    // Ensure i18n is using the correct language
    i18n.changeLanguage(language);
  
    // Return the language to be used for `currentLanguage` in state
    return language;
  };
  

// Define item mapping for English and French
const itemMapping = {
  fr: {
    "porte": "door",                    // Maps "porte" to "door"
    "fenêtres": "windows",              // Maps "fenêtres" to "windows"
    "fenetre": "windows",               // Maps "fenetre" (singular) to "windows"
    "porte de garage": "garagedoor",    // Maps "porte de garage" to "garagedoor"
    "fenetres": "windows",              // Handles missing accents
  },
  en: {
    "door": "Door",
    "windows": "Windows",
    "garagedoor": "Garage Door"
  }
};

const normalizeItemKey = (key) => {
  return key
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '') // Remove all spaces
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, ""); // Remove accents
};

const normalizeText = (text) => {
  return text
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '') // Remove spaces
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, ""); // Remove accents
};





// Updated processAssistantResponse function in FacadeDesigner.js
const processAssistantResponse = (response) => {
  console.log("current language: ", currentLanguage);

  // Updated regex to handle newlines and varying whitespace
  const summaryRegex = currentLanguage === 'fr'
    ? /<h3>Résumé des Couleurs Proposées<\/h3>\s*<p>([\s\S]*?)<\/p>/i
    : /<h3>Summary of Proposed Colors<\/h3>\s*<p>([\s\S]*?)<\/p>/i;

  const match = response.match(summaryRegex);

  if (match && match[1]) {
    const summary = match[1];

    // Split the summary by line breaks or commas
    const itemColorPairs = summary.split(/<br\s*\/?>|\n/).map(pair => pair.trim()).filter(Boolean);
    const combinedColors = {};

    itemColorPairs.forEach(pair => {
      // Ensure each entry is split by the first colon only
      const [item, colors] = pair.split(/:\s*(.+)/);
      if (item && colors) {
        const colorList = colors
          .split(currentLanguage === 'fr' ? ' et ' : ' and ')
          .map(color => normalizeToCamelCase(color.trim()));

        const itemKey = normalizeToCamelCase(item);

        // Translate the item based on current language and mapping
        const translatedItem = (currentLanguage === 'fr' && itemKey === 'fenetres') ? 'window' : itemMapping[currentLanguage][itemKey] || itemKey;

        combinedColors[translatedItem] = colorList;
      }
    });

    setProposedColors(prevColors => ({ ...prevColors, ...combinedColors }));

    const newColorButtons = Object.keys(combinedColors).flatMap(item => {
      const colors = combinedColors[item];
      return colors.length === 1 ? [`${item} ${colors[0]}`] : colors.map(color => `${item} ${color}`);
    });

    // If each item has only one color, show "Show Me"; otherwise, show individual color buttons
    setColorButtons(Object.values(combinedColors).every(colors => colors.length === 1) ? ["Show Me"] : newColorButtons);
  } else {
    console.warn("Regex match failed or no color summary found in assistant response.");
  }
};

  
  
  const itemNameMapping = {
    "porte": "door",
    "portes": "door",
    "fenêtre": "windows",
    "fenêtres": "windows",
    "window": "windows",
    "garage door": "garagedoor", // Standardize "garage door" as "garagedoor" without space
    "garagedoor": "garagedoor"
  };
  
  // Function to normalize item names based on common variations
  const normalizeItemName = (name) => {
    const formattedName = name.toLowerCase().trim(); // Convert to lowercase and trim whitespace
    return itemNameMapping[formattedName] || formattedName; // Return the mapped name or the original if not mapped
  };
  const uploadImage = async (imageUrl) => {
    try {
      setStatusMessage(t('assistant.statusUploading'));
      const toastId = toast.info(t('assistant.toastUploading'), { autoClose: false });

      const response = await fetch('/.netlify/functions/openAIImageUpload', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ imageUrl }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      toast.update(toastId, { render: t('assistant.toastUploadComplete'), type: toast.TYPE.SUCCESS, autoClose: 5000 });
      return data.file_id;
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error(t('assistant.toastError'));
      setStatusMessage(t('assistant.statusError'));
      setIsLoading(false);
      throw error;
    }
  };

  const startConversation = async (userInput, fileId) => {
    // console.log("Using runId in Start Conversation:", runId); // Log the runId value here for debugging 

    try {
      setStatusMessage(t('assistant.statusAnalyzing'));
      const toastId = toast.info(t('assistant.toastAnalyzing'), { autoClose: false });

      const response = await fetch('/.netlify/functions/openAIConversation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userInput, fileId, assistantId: 'asst_BEjQnYuZGaZvdVy5XCwaZcj5' }),
        // body: JSON.stringify({ userInput, fileId, assistantId: 'asst_oUcoz4KPgfGmzYGGjjREFGJi', threadId}),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setThreadId(data.threadId);
      // setRunId(data.runId);
      toast.update(toastId, { render: t('assistant.toastComplete'), type: toast.TYPE.SUCCESS, autoClose: 5000 });
      handleAnalyticsEvent('DesignerResponse', 'Designer', 'Designer Responded');

        //  console.log("Updated Thread ID and Run ID:", data.threadId, data.runId);

      // Ensure the response is treated as a string
      const responseText = typeof data.message === 'string' ? data.message : JSON.stringify(data.message);

      // Add the new conversation to the history
      setConversationHistory(prev => [{ question: userInput, response: responseText }, ...prev]);

      return responseText;
    } catch (error) {
      console.error('Error starting conversation:', error);
      toast.dismiss();
      toast.error(t('assistant.toastError'));
      setStatusMessage(t('assistant.statusError'));
      setIsLoading(false);
      throw error;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    
    try {
      const currentInput = userInput.trim();
      if (!currentInput && !isFirstInteraction) {
        throw new Error(t("assistant.noInput"));
      }
  
      const imageUrl = isFirstInteraction ? backgroundImageUrl : imageFileId;
      if (!imageUrl && isFirstInteraction) {
        throw new Error(t("assistant.noImage"));
      }
  
      const question = currentInput || defaultQuestion;
      const isGuest = currentUser.email.includes('guest');
      const plan = planLimits.designer[userData.planType] || { totalInteractions: 10, weeklyInteractions: 5 };
  
      if (userRole !== 'admin' && userRole !== 'mainAdmin') {
        let assistantInteractionCount, weeklyInteractionCount;
  
        if (isGuest) {
          assistantInteractionCount = parseInt(localStorage.getItem('guest_assistantInteractionCount')) || 0;
          weeklyInteractionCount = parseInt(localStorage.getItem('guest_weeklyInteractionCount')) || 0;
        } else {
          assistantInteractionCount = userData.assistantInteractionCount || 0;
          weeklyInteractionCount = userData.weeklyInteractionCount || 0;
        }
  
        if (assistantInteractionCount >= plan.totalInteractions || weeklyInteractionCount >= plan.weeklyInteractions) {
          setAssistantResponse(t("assistant.limitReached"));
          setIsLoading(false);
          return;
        }
      }
  
      let uploadedImageFileId = imageFileId;
  
      if (isFirstInteraction && !imageFileId) {
        uploadedImageFileId = await uploadImage(imageUrl);
        setImageFileId(uploadedImageFileId);
      }
  
      const responseMessage = await startConversation(question, uploadedImageFileId);
      processAssistantResponse(responseMessage);
      setStatusMessage(t('assistant.statusComplete'));
      toast.success(t('assistant.toastComplete'));
      setIsFirstInteraction(false);
      updateInteractionCounts();
    } catch (error) {
      console.error("Error:", error);
      setAssistantResponse(prev => `${prev}\n\n${t("assistant.errorAi")}`);
      toast.error(t("assistant.errorNetwork"));
    } finally {
      setIsLoading(false); // Ensure loading is reset after try/catch
      setUserInput(''); // Clear user input after handling submit
    }
  };
  
  
  

  // Centralized Project Switch Logic with Conditions
  const handleProjectSwitch = (newImageUrl) => {
    // Check if this is a *new* project switch or a redundant switch.
    if (newImageUrl !== backgroundImageUrl) {
      // console.log("New project switch detected. Setting states...");
      setThreadId(null);
      setRunId(null);
      setImageFileId(null);
      setBackgroundImageUrl(newImageUrl);
      setUserInput('');
      setAssistantResponse(''); // Reset the assistant response
      setColorButtons([]);
      setProposedColors({});
      setIsFirstInteraction(true); // Set to true only for a *new* switch
      setIsDetectionTriggered(false);
      setConversationHistory([]); // Clear conversation history
      setStatusMessage('');
    } else {
      // console.log("Redundant project switch detected. No state changes applied.");
    }
  };
  

  const handleHintClick = (hintText) => {
    setUserInput(hintText);
    textAreaRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const startNewConversation = async () => {
    setUserInput('');
    setAssistantResponse('');
    setIsLoading(false);
    // handleProjectSwitch('');
    resetNewProjectImage();
    setConversationHistory([])
    if (currentUser) {
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, { threadId: null, runId: null });
    }
  };

  const updateInteractionCounts = async (threadId, runId) => {

    // console.log("environement", process.env.REACT_APP_ENV )

    if (process.env.REACT_APP_ENV === 'development') {
      // console.log("Skipping interaction count updates in development mode.");
      return;
    }

    if (currentUser && currentUser.email.includes('guest')) {
        // Retrieve interaction counts from local storage
        let weeklyInteractionCount = parseInt(localStorage.getItem('guest_weeklyInteractionCount')) || 0;
        let assistantInteractionCount = parseInt(localStorage.getItem('guest_assistantInteractionCount')) || 0;

        // Increment the counts
        weeklyInteractionCount += 1;
        assistantInteractionCount += 1;

        // Update local storage
        localStorage.setItem('guest_weeklyInteractionCount', weeklyInteractionCount);
        localStorage.setItem('guest_assistantInteractionCount', assistantInteractionCount);

        // console.log("Guest interaction counts updated in local storage:", { weeklyInteractionCount, assistantInteractionCount });

        // Update the local state to trigger a re-render
        setUserData((prevData) => ({
            ...prevData,
            weeklyInteractionCount,
            assistantInteractionCount
        }));

    } else if (currentUser && userRole !== 'mainAdmin' && userRole !== 'admin') {
        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
            const userData = userDoc.data();
            const weeklyInteractionCount = (userData.weeklyInteractionCount || 0) + 1;
            const assistantInteractionCount = (userData.assistantInteractionCount || 0) + 1;

            // console.log("Updating interaction counts in Firestore:", { weeklyInteractionCount, assistantInteractionCount, threadId, runId });

            const updateData = {
                weeklyInteractionCount,
                assistantInteractionCount,
            };

            if (threadId !== undefined) {
                updateData.threadId = threadId;
            }

            if (runId !== undefined) {
                updateData.runId = runId;
            }

            await updateDoc(userRef, updateData);

            // Update the local state to trigger a re-render
            setUserData((prevData) => ({
                ...prevData,
                weeklyInteractionCount,
                assistantInteractionCount
            }));
        }
    }
};




  const convertTextUrlsToLinks = (text) => {
    const urlRegex = /www\.[\w\-\.]+(\.com|\.ca)/g;
    return text.replace(urlRegex, (url) => {
      return `<a href="http://${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const createMarkup = (htmlContent) => {
    const content = typeof htmlContent === 'string' ? htmlContent : JSON.stringify(htmlContent);
    const processedContent = convertTextUrlsToLinks(content);
    return { __html: DOMPurify.sanitize(processedContent) };
  };


  const handleModalOpen = () => {
    setDesignerModalOpen(true);
    handleAnalyticsEvent('click', 'Designer', 'Designer Triggered'); // Log event when modal is opened
  };

  const renderButtons = () => {
    if (!colorButtons.length || !colorButtons[0]) {
      // If colorButtons is empty or the first element is undefined, return null or a fallback button
      return null;
    }
  
    // Safely access and split colorButtons[0]
    const buttonText = colorButtons[0];
    const [item, colorName] = buttonText.split(' ');
  
    // Ensure proposedColors[item] is available before accessing it
    const backgroundColor = getColorByName(proposedColors[item?.toLowerCase().replace(' ', '')]?.[0]);
  
    const handleClick = async () => {
      try {
        setDesignerModalOpen(false);
        if (!isDetectionTriggered) {
          await detectAndApplyColors(proposedColorsRef.current);
          setIsDetectionTriggered(true);  // Mark detection as triggered
        } else {
          await applyColorsToCanvasItems(proposedColorsRef.current);  // Only apply colors if detection has already been done
        }
      } catch (error) {
        console.error("Error during button click processing:", error);
      }
    };
  
    return (
      <div>
        <Button
          variant="outline-primary"
          className="color-button"
          style={{ margin: '5px', backgroundColor }}
          onClick={handleClick}
          title={colorName}
        >
          {isDetectionTriggered ? t('assistant.ApplyColors') : t('assistant.ShowMe')}
        </Button>
        <p>{t('assistant.buttonWarningMessage')}</p>
      </div>
    );
  };
  




  return (
    <>
      {!isDesignerModalOpen && !isMobile && (
        <div className="floating-button" onClick={handleModalOpen}>
          <img
            src="/rnewFacadeDesignerProIcon_cutout.png"
            alt="Facade Designer Icon"
            className="designer-icon"
            style={{ width: '40px', height: "auto", marginRight: '8px', verticalAlign: 'middle' }}
          />
          <span>{t('assistant.VirtualDesigner')}</span>
        </div>
      )}
      <Modal show={isDesignerModalOpen} onHide={() => setDesignerModalOpen(false)}>
        <Modal.Header closeButton>
          <div className="modal-header-content">
            <Modal.Title>{t("assistant.VirtualDesigner")}</Modal.Title>
            <div className="interaction-counts">
              {`${userData.weeklyInteractionCount || 0} ${t('assistant.outOf')} ${planLimits.designer[userData.planType]?.weeklyInteractions || 'N/A'} ${t('assistant.weekly')}`}
              <br />
              {`${userData.assistantInteractionCount || 0} ${t('assistant.outOf')} ${planLimits.designer[userData.planType]?.totalInteractions || 'N/A'} ${t('assistant.monthly')}`}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body ref={modalBodyRef}>
  <div className='avatarTitle'>
    <h4>{t("assistant.avatarTitle")} </h4>
  </div>
  <div className="avatar-container" style={{ textAlign: 'center', paddingBottom: "16px" }}>
    <img src={"/rnewFacadeDesignerPro_cutout300.png"} alt="Facade Designer Avatar" style={{ width: '200px', margin: '0 auto' }} />
  </div>
  <div className='avatarTitle'>
    <p>{t("assistant.hintExamples")}</p>
  </div>
  <div className="hints-grid">
    {t('hints', { returnObjects: true }).map((hint, index) => (
      <div key={index} className="hint-box" onClick={() => handleHintClick(hint)}>
        {hint}
      </div>
    ))}
  </div>

  {/* Display the assistant response if available */}
  {assistantResponse && (
    <div className="assistant-response">
      <p>{assistantResponse}</p>
    </div>
  )}

  {/* Move Spinner Here */}
  {isLoading && (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <Spinner animation="border" role="status" className="spinner">
        <span className="sr-only">{t('assistant.loading')}</span>
      </Spinner>
    </div>
  )}

  {!isLoading && (
    <>
      <p>{statusMessage}</p>
      <div ref={responseContainerRef}>
        {/* Render the conversation history in reverse order */}
        {conversationHistory.slice().reverse().map((conv, index) => (
          <div key={index}>
            <p><strong>{t("assistant.You")}:</strong> {conv.question}</p>
            <div dangerouslySetInnerHTML={createMarkup(conv.response)} />
          </div>
        ))}
      </div>
      <div className="color-buttons-container">
        {renderButtons()}
      </div>
      {/* User question section with ref for scrolling */}
      <div ref={textareaContainerRef}>
        <textarea
          ref={textAreaRef}
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          disabled={isLoading}
          placeholder={t("assistant.placeHolder")}
        />
        <div className="spinner-container">
          <Button onClick={handleSubmit} disabled={isLoading}>{t("assistant.Submit")}</Button>
        </div>
      </div>
    </>
  )}
</Modal.Body>

      </Modal>
    </>
  );
  
  
};

export default FacadeDesigner;