// ImageSlider.js
import React from 'react';
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';

const ImageSlider = ({ images }) => {
  const { t } = useTranslation(); // Use the translation function

  // Remove the first image (original) from the slider images array
  const sliderImages = images.slice(1);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {sliderImages.map((image, index) => (
          <div key={index}>
            {/* Image */}
            <img src={image} alt={`House style ${index + 1}`} className="slider-image" />
            
            {/* Description for each image */}
            <p className="slider-description">{t(`slider.descriptions.${index + 1}`)}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
