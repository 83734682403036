/* global EyeDropper */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons';

const EyeDropperComponent = (props) => {
    const { t, i18n } = useTranslation(["translation"]);
    const [color, setColor] = useState('#ffffff');

    const isEyeDropperSupported = 'EyeDropper' in window;

    const onColorPick = (pickedColor) => {
        // Assuming 'pickedColor' is the color selected by EyeDropper
        props.onColorSelected(pickedColor); // Callback to parent component
    };

    const pickColor = async () => {
        if (isEyeDropperSupported) {
            try {
                const eyeDropper = new EyeDropper();
                const result = await eyeDropper.open();
                setColor(result.sRGBHex);
                onColorPick(result.sRGBHex); // Pass the selected color to the parent component
                console.log("color code ", color)
            } catch (e) {
                console.error("Error using Eye Dropper API", e);
            }
        } else {
            console.error("Eye Dropper API is not supported in this browser");
        }

    };


    return (
        <div>
            <button onClick={pickColor} disabled={!isEyeDropperSupported}><FontAwesomeIcon icon={faEyeDropper} /> {t("itemColor-pickColor")}</button>

            {t("eyedropper-lasColor")}

            <div style={{ backgroundColor: color, width: '40px', height: '40px' }}></div>
        </div>
    );
};

export default EyeDropperComponent;
