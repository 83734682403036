// colorUtils.js

// Import your colorList here
import { colorList } from './colorHelper'; // Make sure to use the correct path to the color data

// Function to normalize text to camelCase format
export const normalizeToCamelCase = (text) => {
  return text
    .normalize("NFD")                  // Separate accents from characters
    .replace(/[\u0300-\u036f]/g, "")    // Remove accents
    .toLowerCase()                     // Convert to lowercase
    .replace(/\s+/g, ' ')              // Replace multiple spaces with a single space
    .trim()                            // Remove leading/trailing spaces
    .split(' ')                        // Split by space to handle multi-word colors
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');                         // Join into a single camelCase string
};

// Consolidated getColorByName function
export const getColorByName = (name) => {
  if (!name) return '#ffffff';

  // Normalize to camelCase for consistent comparison
  const formattedName = normalizeToCamelCase(name);
  console.log(`Matching color for: ${formattedName}`);

  // Search for the formatted name in the color list using both `name` and `fr` fields
  const colorObj = colorList.find(color =>
    normalizeToCamelCase(color.name) === formattedName ||
    normalizeToCamelCase(color.fr) === formattedName
  );

  console.log(`Matched color: ${colorObj ? colorObj.color : 'No Match Found'}`);
  return colorObj ? colorObj.color : '#ffffff';
};


