export const colorList = [
  { color: "rgba(0, 0, 0, 1)", name: "black", fr: "noir" },
  { color: "rgba(255, 255, 255, 1)", name: "white", fr: "blanc" },
  { color: "rgba(219, 199, 164, 1)", name: "antiqueIvory", fr: "ivoireAntique" },
  { color: "rgba(255, 243, 200, 1)", name: "sandstone", fr: "vanille" },
  { color: "rgba(187, 174, 155, 1)", name: "cashmere", fr: "cachemire" },
  { color: "rgba(251, 239, 181, 1)", name: "maize", fr: "ivoire" },
  { color: "rgba(216, 181, 140, 1)", name: "sandalwood", fr: "sable" },
  { color: "rgba(248, 231, 157, 1)", name: "almond", fr: "amande" },
  { color: "rgba(216, 180, 120, 1)", name: "wicker", fr: "café" },
  { color: "rgba(215, 183, 116, 1)", name: "canyonClay", fr: "argile" },
  { color: "rgba(191, 155, 107, 1)", name: "harvestWheat", fr: "bléMur" },
  { color: "rgba(183, 146, 102, 1)", name: "pebble", fr: "kaki" },
  { color: "rgba(216, 163, 95, 1)", name: "brownstone", fr: "beigeAntique" },
  { color: "rgba(187, 165, 144, 1)", name: "tan", fr: "tan" },
  { color: "rgba(147, 129, 107, 1)", name: "darkDriftwood", fr: "boisEpave" }, // "d'épave" becomes "Epave"
  { color: "rgba(139, 113, 96, 1)", name: "saddleBrown", fr: "brunFauve" },
  { color: "rgba(136, 71, 51, 1)", name: "venetianRed", fr: "rougeVenitien" },
  { color: "rgba(166, 172, 158, 1)", name: "sage", fr: "sauge" },
  { color: "rgba(79, 101, 99, 1)", name: "ivyGreen", fr: "ardoise" },
  { color: "rgba(93, 106, 89, 1)", name: "moonlitMoss", fr: "pierreLune" },
  { color: "rgba(2, 66, 14, 1)", name: "forestGreen", fr: "vertForêt" },
  { color: "rgba(94, 114, 125, 1)", name: "wedgewoodBlue", fr: "bleuWedgewood" },
  { color: "rgba(103, 113, 122, 1)", name: "midnightSurf", fr: "vaguesMinuit" },
  { color: "rgba(32, 49, 67, 1)", name: "oldWorldBlue", fr: "bleuOcéan" },
  { color: "rgba(167, 169, 168, 1)", name: "doverGray", fr: "grisGranite" },
  { color: "rgba(133, 126, 118, 1)", name: "storm", fr: "grisOrageux" },
  { color: "rgba(118, 102, 76, 1)", name: "slate", fr: "charbon" },
  { color: "rgba(90, 87, 80, 1)", name: "windsweptSmoke", fr: "ventFumée" },  // "de fumée" becomes "Fumée"
  { color: "rgba(158, 85, 17, 1)", name: "aluCopper", fr: "aluminiumCuivre" },
  { color: "rgba(77, 45, 24, 1)", name: "nutmeg", fr: "brunMuscade" },
  { color: "rgba(71, 40, 29, 1)", name: "chestnutBrown", fr: "brunMarron" },
  { color: "rgba(75, 54, 32, 1)", name: "commercialBrown", fr: "brunCommercial" },
  { color: "rgba(128, 0, 32, 1)", name: "burgundy", fr: "bourgogne" },
  { color: "rgba(103, 97, 85, 1)", name: "fineSand", fr: "sablon" },
  { color: "rgba(58, 50, 48, 1)", name: "antiqueBrown", fr: "brunAntique" },
  { color: "rgba(131, 122, 105, 1)", name: "windowBronze", fr: "bronzeFenêtre" },
  { color: "rgba(185, 179, 158, 1)", name: "aluminium", fr: "aluminium" },
  { color: "rgba(63, 59, 47, 1)", name: "ironOre", fr: "mineraiFer" },
  { color: "rgba(83, 89, 85, 1)", name: "graphite", fr: "graphite" },
  { color: "rgba(102, 84, 60, 1)", name: "espresso", fr: "espresso" },
  { color: "rgba(126, 128, 89, 1)", name: "mountainArbor", fr: "buissonMontagne" },
  { color: "rgba(116, 135, 150, 1)", name: "rockwellBlue", fr: "bleuRockwell" },
  { color: "rgba(56, 94, 115, 1)", name: "coastalBlue", fr: "bleuLittoral" },
  { color: "rgba(100, 100, 100, 1)", name: "anthracite", fr: "anthracite" },
  { color: "rgba(196, 30, 58, 1)", name: "brickRed", fr: "rougeBrique" },
  { color: "rgba(166, 47, 32, 1)", name: "autumnRed", fr: "rougeAutomne" },
  { color: "rgba(196, 30, 58, 1)", name: "classicRed", fr: "rougeClassique" }
];
