// src/colors/presetColors.js
const presetColors = [
    { color: "rgba(0, 0, 0, 1)", titleKey: "itemColor-black" },
    { color: "rgba(255, 255, 255, 1)", titleKey: "itemColor-brightWhite" },
    { color: "rgba(243, 244, 246, 1)", titleKey: "itemColor-softWhite" },
    { color: "rgba(225, 233, 236, 1)", titleKey: "itemColor-iceWhite" },
    { color: "rgba(255, 243, 200, 1)", titleKey: "itemColor-sandstone" },
    { color: "rgba(239, 216, 168, 1)", titleKey: "itemColor-cashmere" },
    { color: "rgba(251, 239, 181, 1)", titleKey: "itemColor-maize" },
    { color: "rgba(219, 199, 164, 1)", titleKey: "itemColor-antiqueIvory" },
    { color: "rgba(216, 181, 140, 1)", titleKey: "itemColor-sandalwood" },
    { color: "rgba(248, 231, 157, 1)", titleKey: "itemColor-almond" },
    { color: "rgba(216, 180, 120, 1)", titleKey: "itemColor-wicker" },
    { color: "rgba(215, 183, 116, 1)", titleKey: "itemColor-canyonClay" },
    { color: "rgba(191, 155, 107, 1)", titleKey: "itemColor-harvestWheat" },
    { color: "rgba(183, 146, 102, 1)", titleKey: "itemColor-pebble" },
    { color: "rgba(216, 163, 95, 1)", titleKey: "itemColor-brownstone" },
    { color: "rgba(187, 165, 144, 1)", titleKey: "itemColor-tan" },
    { color: "rgba(147, 129, 107, 1)", titleKey: "itemColor-darkDrift" },
    { color: "rgba(139, 113, 96, 1)", titleKey: "itemColor-saddleBrown" },
    { color: "rgba(136, 71, 51, 1)", titleKey: "itemColor-venetianRed" },
    { color: "rgba(166, 172, 158, 1)", titleKey: "itemColor-sage" },
    { color: "rgba(79, 101, 99, 1)", titleKey: "itemColor-ivyGreen" },
    { color: "rgba(93, 106, 89, 1)", titleKey: "itemColor-moonlitMoss" },
    { color: "rgba(2, 66, 14, 1)", titleKey: "itemColor-forestGreen" },
    { color: "rgba(94, 114, 125, 1)", titleKey: "itemColor-wedgewoodBlue" },
    { color: "rgba(103, 113, 122, 1)", titleKey: "itemColor-midnightSurf" },
    { color: "rgba(32, 49, 67, 1)", titleKey: "itemColor-oldWorldBlue" },
    { color: "rgba(167, 169, 168, 1)", titleKey: "itemColor-doverGray" },
    { color: "rgba(133, 126, 118, 1)", titleKey: "itemColor-storm" },
    { color: "rgba(118, 102, 76, 1)", titleKey: "itemColor-slate" },
    { color: "rgba(90, 87, 80, 1)", titleKey: "itemColor-windsweptSmoke" },
    { color: "rgba(158, 85, 17, 1)", titleKey: "itemColor-aluCopper" },
    { color: "rgba(77, 45, 24, 1)", titleKey: "itemColor-nutmeg" },
    { color: "rgba(71, 40, 29, 1)", titleKey: "itemColor-chestnutBrown" },
    { color: "rgba(75, 54, 32, 1)", titleKey: "itemColor-commercialBrown" },
    { color: "rgba(118, 40, 37, 1)", titleKey: "itemColor-burgundy" },
    { color: "rgba(103, 97, 85, 1)", titleKey: "itemColor-fineSand" },
    { color: "rgba(58, 50, 48, 1)", titleKey: "itemColor-antiqueBrown" },
    { color: "rgba(131, 122, 105, 1)", titleKey: "itemColor-windowBronze" },
    { color: "rgba(185, 179, 158, 1)", titleKey: "itemColor-aluminium" },
    { color: "rgba(63, 59, 47, 1)", titleKey: "itemColor-ironOre" },
    { color: "rgba(83, 89, 85, 1)", titleKey: "itemColor-graphite" },
    { color: "rgba(52, 61, 65, 1)", titleKey: "itemColor-anthracite" },
    { color: "rgba(102, 84, 60, 1)", titleKey: "itemColor-espresso" },
    { color: "rgba(126, 128, 89, 1)", titleKey: "itemColor-mountainArbor" },
    { color: "rgba(116, 135, 150, 1)", titleKey: "itemColor-rockwellBlue" },
    { color: "rgba(56, 94, 115, 1)", titleKey: "itemColor-coastalBlue" },
  ];

//   export const fenplastColors =  [
//       { color: "rgba(255, 255, 255, 1)", title: t("itemColor-standardWhite") },
//       { color: "rgba(248, 231, 157, 1)", title: t("itemColor-almond") },
//       { color: "rgba(215, 183, 116, 1)", title: t("itemColor-canyonClay") },
//       { color: "rgba(216, 163, 95, 1)", title: t("itemColor-brownstone") },
//       { color: "rgba(118, 40, 37, 1)", title: t("itemColor-burgundy") },
//       { color: "rgba(75, 54, 32, 1)", title: t("itemColor-commercialBrown") },
//       { color: "rgba(71, 40, 29, 1)", title: t("itemColor-chestnutBrown") },
//       { color: "rgba(77, 45, 24, 1)", title: t("itemColor-nutmeg") },
//       { color: "rgba(187, 174, 155, 1)", title: t("itemColor-cashmere") },
//       { color: "rgba(216, 180, 120, 1)", title: t("itemColor-wicker") },
//       { color: "rgba(197, 102, 2, 1)", title: t("itemColor-cedar") },
//       { color: "rgba(118, 102, 76, 1)", title: t("itemColor-slate") },
//       { color: "rgba(240, 213, 152, 1)", title: t("itemColor-cream") },
//       { color: "rgba(185, 179, 158, 1)", title: t("itemColor-anodizedGrey") },
//       { color: "rgba(251, 239, 181, 1)", title: t("itemColor-maize") },
//       { color: "rgba(183, 146, 102, 1)", title: t("itemColor-pebble") },
//       { color: "rgba(63, 59, 47, 1)", title: t("itemColor-ironOre") },
//       { color: "rgba(0, 0, 0, 1)", title: t("itemColor-black") },
//       { color: "rgba(103, 39, 14, 1)", title: t("itemColor-countryRed") },
//       { color: "rgba(156, 49, 54, 1)", title: t("itemColor-brickRed") },
//       { color: "rgba(166, 47, 32, 1)", title: t("itemColor-autumnRed") },
//       { color: "rgba(196, 30, 58, 1)", title: t("itemColor-classicRed") },
//       { color: "rgba(156, 49, 54, 1)", title: t("itemColor-brickRed") },
//       { color: "rgba(216, 181, 140, 1)", title: t("itemColor-sandalwood") },
//       { color: "rgba(122, 99, 76, 1)", title: t("itemColor-sable") },
//       { color: "rgba(138, 92, 54, 1)", title: t("itemColor-woodSierra") },
//       { color: "rgba(255, 243, 200, 1)", title: t("itemColor-sandstone") },
//       { color: "rgba(2, 66, 14, 1)", title: t("itemColor-forestGreen") },
//     ];
 

//   export const gitHubColorSelection = [
//     "#000000",
//     "#ffffff",
//     "#9798f7",
//     "#e1e9ec",
//     "#fff3c8",
//     "#efd8a8",
//     "#fbefb5",
//     "#dbc7a4",
//     "#d8b58c",
//     "#f8e79d",
//     "#d8b478",
//     "#d7b774",
//     "#bf9b6b",
//     "#b79266",
//     "#d8a35f",
//     "#bba590",
//     "#93816b",
//     "#8b7160",
//     "#884733",
//     "#a6ac9e",
//     "#4f6563",
//     "#5d6a59",
//     "#02420e",
//     "#5e727d",
//     "#67717a",
//     "#203143",
//     "#a7a9a8",
//     "#857e76",
//     "#76664c",
//     "#5a574d",
//     "#9e5511",
//     "#4d2d18",
//     "#472819",
//     "#4b3620",
//     "#762825",
//     "#676155",
//     "#3a322f",
//     "#837a69",
//     "#b9b39e",
//     "#3f3b2f",
//     "#535955",
//     "#66543c",
//     "#7e8059",
//     "#748796",
//     "#385e73"
//   ];
  
  export default presetColors;
  