import { fabric } from "fabric-with-erasing";
import { v1 as uuid } from "uuid";
import windowBackground from "../../images/backgroundDouble.png";
import { dfItemColor, saveCanvasState } from "../../helpers/helpers";
import itemsBackgroundsArray from "../../inventaire/itemsBackgroundsArray";
import { getPsptFabricObj } from "../../helpers/fabricPerspective";

let windowImage;
let optionImage;
let backgroundUrl = windowBackground;

export function addWindows(canvas, properties, callbackFn, realScaleFactor) {
  var tName = properties.tName;
  var typeName = properties.typeName;
  var url = properties.window;
  var windowURL = windowURL;
  var optionUrl = optionUrl;
  var window = properties.window;

  findBackground(tName);

  let promises = [];

  let loadWindowImageImage = new Promise((resolve, reject) => {


    fabric.Image.fromURL(
      url,
      function (oImg) {
        const naturalWidth = oImg.width;
        const naturalHeight = oImg.height;

        adjustImageScale(oImg, properties, naturalWidth, naturalHeight);
        windowImage = oImg;

        windowImage.set({
          windowItemId: uuid(),
          windowType: tName,
          windowImageURL: windowURL,
          crossOrigin: "anonymous",
          itemType: "window"
        });
        var filter = new fabric.Image.filters.BlendColor({
          color: dfItemColor,
          mode: "multiply",
          alpha: 0.95,
        });

        // windowImage.scaleToWidth(160);
        windowImage.filters.push(filter);
        windowImage.applyFilters();
        canvas.centerObject(windowImage);
        resolve(windowImage);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  });

  promises.push(loadWindowImageImage);

  optionUrl = backgroundUrl;

  //add options like grid
  let loadOptionImageImage = new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      optionUrl,
      function (oImg) {
        const naturalWidth = oImg.width;
        const naturalHeight = oImg.height;

        adjustImageScale(oImg, properties, naturalWidth, naturalHeight);
        optionImage = oImg;

        optionImage.set({
          optionItemId: uuid(),
          optionType: tName,
          windowGridType: properties.windowGridType,
          crossOrigin: "anonymous",
          originX: "center",
          originY: "center",
        });

        canvas.centerObject(optionImage);
        canvas.uniformScaling = false;
        resolve(optionImage);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  });

  promises.push(loadOptionImageImage);

  // add backgroung Image
  let loadBackgroundImageImage = new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      backgroundUrl,
      function (oImg) {
        const naturalWidth = oImg.width;
        const naturalHeight = oImg.height;

        adjustImageScale(oImg, properties, naturalWidth, naturalHeight);
        let backgroundImage = oImg;

        backgroundImage.set({
          backgroundItemId: uuid(),
          crossOrigin: "anonymous",
        });
        canvas.centerObject(backgroundImage);
        // backgroundImage.scaleToWidth(160);
        canvas.uniformScaling = false;
        resolve(backgroundImage);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  });

  promises.push(loadBackgroundImageImage);

  let topPosition, leftPosition;

  if (properties.isAICall) {
    // AI-determined positions take precedence if available
    topPosition = properties.top !== undefined ? properties.top : 100; // Fallback to default if not provided
    leftPosition = properties.left !== undefined ? properties.left : canvas.getWidth() / 2; // Fallback to default if not provided
  } else {
    // For manual selections or replacements, use provided coordinates if available, else default
    topPosition = (properties.top !== undefined) ? properties.top : 100; // You might adjust this default as per your UI/UX needs
    leftPosition = (properties.left !== undefined) ? properties.left : canvas.getWidth() / 2; // Adjust accordingly
  }


  Promise.all(promises)
    .then((images) => {
      let windowImage = images[0];
      let optionImage = images[1];
      let backgroundImage = images[2];

      let windowStackImage = new fabric.Group(
        [backgroundImage, optionImage, windowImage],
        {
          windowStackImageId: uuid(),
          groupId: uuid(),
          dirty: true,
          selectable: true,
          itemUrl: window,
          itemColor: dfItemColor,
          windowType: tName,
          preserveObjectStacking: true,
          top: topPosition,
          left: leftPosition,
          originalOpacity: 1,
          // Dynamically include or exclude width and height
          ...(properties.width !== undefined ? { width: properties.width } : {}),
          ...(properties.height !== undefined ? { height: properties.height } : {}),
          usePerspective: true,
        }
      );

      getPsptFabricObj(canvas, windowStackImage).then((poly) => {
        canvas.discardActiveObject().renderAll();
        canvas.add(poly);
        poly.itemColor = dfItemColor;
        poly.itemUrl = window;
        poly.windowType = tName;
        canvas.bringToFront(poly);
        if (callbackFn) callbackFn();
        canvas.setActiveObject(poly);

        if (canvas.isPerspective) {
          windowStackImage.visible = false;
          canvas.add(windowStackImage);
        }

      });
      // Properties object contains detected pixel dimensions
      const objectPixelWidth = properties.width;
      const objectPixelHeight = properties.height;

      const realWidthFactor = realScaleFactor;

      const realWidth = (objectPixelWidth / realWidthFactor).toFixed(1);
      const realHeight = (objectPixelHeight / realWidthFactor).toFixed(1);


      // Assume windowStackImage is already created and has a known position and dimensions
      const textPositionLeft = windowStackImage.left - windowStackImage.width / 3;
      const textPositionTop = windowStackImage.top;

      const textInfo = `W: ${realWidth} in, H: ${realHeight} in`; // Display in inches

      // Create a Fabric.js text object for this information
      const textObject = new fabric.Text(textInfo, {
        fontSize: 12,
        fill: 'black',
        fontFamily: 'Arial',
        left: textPositionLeft,
        top: textPositionTop,
        angle: -90, // Rotate the text object by 90 degrees to make it vertical
        originX: 'center', // This centers the text horizontally around its midpoint
      });

      // Measure text to create a background rectangle
      const textWidth = textObject.width + 10; // Adding some padding
      const textHeight = textObject.height; // Adding some padding

      // Create a rectangle to serve as the background
      const backgroundRect = new fabric.Rect({
        width: textWidth,
        height: textHeight,
        fill: 'yellow',
        left: textPositionLeft, // Slightly offset to center behind the text
        top: textPositionTop, // Slightly offset above the text
        angle: -90, // Rotate the text object by 90 degrees to make it vertical
        rx: 5, // Optional: add rounded corners
        ry: 5, // Optional: add rounded corners
        originX: 'center', // Adjust originX and originY as needed for correct positioning  
      });

      // Group the text and background so they move together
      const textWithBackground = new fabric.Group([backgroundRect, textObject], {
        left: textPositionLeft,
        top: textPositionTop,
        isDimension: true, // custom attribute to mark dimension objects
        selectable: false, // Make the object unselectable
      });

      // Add the grouped text and background to the canvas
      if (!properties.designerTriggered && properties.isAICall && properties.isDimensionsVisible) { canvas.add(textWithBackground);canvas.bringToFront(textWithBackground) }

      saveCanvasState(canvas);
      canvas.renderAll();
      // The rest of your existing code...
    })
    .catch((error) => {
      console.error("Error loading images:", error);
    });

}

// we add a standard background
function findBackground(tName) {

  const itemName = tName;

  let tempBg = itemsBackgroundsArray.filter((item) =>
    item.name.includes(itemName)
  );
  backgroundUrl = tempBg[0].bgUrl;

}
function adjustImageScale(oImg, properties, naturalWidth, naturalHeight) {
  if (properties.isAICall) {
    // AI-determined scale factors based on the target dimensions
    const scaleX = properties.width / naturalWidth;
    const scaleY = properties.height / naturalHeight;
    oImg.scaleX = scaleX;
    oImg.scaleY = scaleY;

  } else if (typeof properties.width === "number" && typeof properties.height === "number") {
    // Explicit check for number type to ensure dimensions are set
    const targetScaleX = properties.width / naturalWidth;
    const targetScaleY = properties.height / naturalHeight;
    oImg.scaleX = targetScaleX;
    oImg.scaleY = targetScaleY;

  } else {
    // Default scaling if no specific dimensions provided
    oImg.scaleX = 0.2;
    oImg.scaleY = 0.2;

  }
}

