// src/components/About.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import "./About.css"; // Optional CSS file for custom styling

const About = () => {
    const { t } = useTranslation();

    return (
        <section className="about-section">
            <div className="about-container">
                <h2>{t('aboutSection.title')}</h2>
                <p>{t('aboutSection.description')}</p>
                <div className="about-details">
                    <div className="about-mission">
                        <h3>{t('aboutSection.missionTitle')}</h3>
                        <p>{t('aboutSection.missionDescription')}</p>
                    </div>
                    <div className="about-vision">
                        <h3>{t('aboutSection.visionTitle')}</h3>
                        <p>{t('aboutSection.visionDescription')}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
