import { fabric } from "fabric-with-erasing";
import { v1 as uuid } from "uuid";
import windowBackground from "../../images/backgroundDouble.png";
import { dfItemColor, saveCanvasState } from "../../helpers/helpers";
import itemsBackgroundsArray from "../../inventaire/itemsBackgroundsArray";
import { getPsptFabricObj } from "../../helpers/fabricPerspective";

let windowImage;
let optionImage;
let backgroundUrl = windowBackground;



export function addGarageDoors(canvas, properties, callbackFn) {
  var tName = properties.tName;
  var typeName = properties.typeName;
  var url = properties.window;
  var windowURL = windowURL;
  var optionUrl = optionUrl;
  var window = properties.window;


  console.log(url)
  findBackground(tName);

  let promises = [];

  let loadWindowImageImage = new Promise((resolve, reject) => {

    fabric.Image.fromURL(
      url,
      function (oImg) {
        windowImage = oImg; // garage door url

        windowImage.set({
          windowItemId: uuid(),
          windowType: tName,
          windowImageURL: windowURL,
          crossOrigin: "anonymous",
          itemType: "garageDoor"
        });
        var filter = new fabric.Image.filters.BlendColor({
          color: dfItemColor,
          mode: "multiply",
          alpha: 0.80,
        });

        windowImage.filters.push(filter);
        windowImage.applyFilters();
        canvas.centerObject(windowImage);
        resolve(windowImage);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  });

  promises.push(loadWindowImageImage);

  optionUrl = backgroundUrl;

  //add options like grid
  let loadOptionImageImage = new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      optionUrl,
      function (oImg) {
        optionImage = oImg;

        optionImage.set({
          optionItemId: uuid(),
          optionType: tName,
          windowGridType: properties.windowGridType,
          crossOrigin: "anonymous",
          originX: "center",
          originY: "center",
        });

        if (optionUrl !== backgroundUrl) {
          var filter = new fabric.Image.filters.BlendColor({
            color: dfItemColor,
            mode: "multiply",
            alpha: 0.95,
          });
        }

        canvas.centerObject(optionImage);
        optionImage.filters.push(filter);
        optionImage.applyFilters();
        canvas.uniformScaling = false;
        resolve(optionImage);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  });

  promises.push(loadOptionImageImage);

  // add backgroung Image
  let loadBackgroundImageImage = new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      backgroundUrl,
      function (oImg) {
        let backgroundImage = oImg;

        backgroundImage.set({
          backgroundItemId: uuid(),
          crossOrigin: "anonymous",
        });
        canvas.centerObject(backgroundImage);
        canvas.uniformScaling = false;
        resolve(backgroundImage);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  });

  promises.push(loadBackgroundImageImage);


  Promise.all(promises)
    .then((images) => {
      let windowImage = images[0];
      let optionImage = images[1];
      let backgroundImage = images[2];

      let windowStackImage = new fabric.Group(
        [backgroundImage, optionImage, windowImage],

        {
          windowStackImageId: uuid(),
          groupId: uuid(),
          dirty: true,
          selectable: true,
          itemUrl: window,
          itemColor: dfItemColor,
          windowType: tName,
          preserveObjectStacking: true,
          originX: "center",
          top: 100,
          scaleY: 0.2,
          scaleX: 0.2,
          usePerspective: true,
          originalOpacity: 1,
        }
      );

      getPsptFabricObj(canvas, windowStackImage).then((poly) => {
        canvas.discardActiveObject().renderAll();
        canvas.add(poly);
        canvas.bringToFront(poly);
        if (callbackFn) callbackFn();
        canvas.setActiveObject(poly);

        if (canvas.isPerspective) {
          windowStackImage.visible = false;
          canvas.add(windowStackImage);
        }
        canvas.renderAll();
      });

    })
    .catch((error) => {
      console.error("Error loading images:", error);
    });

}

// we add a standard background
function findBackground(tName) {

  console.log(tName)

  const itemName = tName;

  let tempBg = itemsBackgroundsArray.filter((item) =>
    item.name.includes(itemName)
  );
  backgroundUrl = tempBg[0].bgUrl;

}

